<template>
  <div class="unload">
    <div class="row">
      <div class="r1c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">设备在线率</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c1-content-wrap">
                <div class="r1-c1-content-row1">
                  <div class="r1-c1-content-row1-item">
                    设备总数
                    <div class="num">{{ onlineData.total }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    使用数量
                    <div class="num">{{ onlineData.online }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    停用数量
                    <div class="num">{{ onlineData.offline }}台</div>
                  </div>
                </div>
                <div class="r1-c1-content-row2">设备在线率</div>
                <div class="r1-c1-content-row3">
                  <div class="max">
                    <div
                      class="val"
                      :style="{
                        width:
                          (onlineData.online / onlineData.total).toFixed(2) * 100 + '%',
                      }"
                    ></div>
                  </div>
                  <div class="percent">
                    {{ (onlineData.online / onlineData.total).toFixed(2) * 100 }}%
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r1c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">设备列表</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1c2-list" v-if="deviceListData.length">
                <div
                  class="r1c2-list-item"
                  v-for="(item, index) in deviceListData"
                  :key="index"
                  :class="[
                    index == currentDeviceIndex ? 'active' : '',
                    item.online == 1 ? '' : 'offline',
                  ]"
                  @click="toggleCurrentDeviceIndex(index)"
                >
                  {{ item.deviceName }}
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r1c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">设备载重数据</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1c3-wrap">
                <div class="menu">
                  <span
                    class="menu-item"
                    v-for="(item, index) in menuList"
                    :key="index"
                    :class="[index == r1c3MenuListCurrentIndex ? 'active' : '']"
                    @click="toggleR1c3MenuListCurrentIndex(index)"
                  >
                    {{ item.name }}
                  </span>
                </div>
                <div class="body" id="echarts-r1c3"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="r2c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">载重报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r2c1-wrap">
                <div class="menu">
                  <span
                    class="menu-item"
                    v-for="(item, index) in menuList"
                    :key="index"
                    :class="[index == r2c1MenuListCurrentIndex ? 'active' : '']"
                    @click="toggleR2c1MenuListCurrentIndex(index)"
                  >
                    {{ item.name }}
                  </span>
                </div>
                <div class="body" id="echarts-r2c1"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r2c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name" id="test">实时数据</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r2-c2-content-wrap">
                <div class="content-top">
                  {{ currentDevice ? currentDevice.deviceName : "" }}
                  （{{ currentDevice ? currentDevice.serialNumber : "" }}）
                  {{
                    currentDeviceRealData.length
                      ? currentDeviceRealData[0].uploadDate
                      : "0000-00-00 00:00:00"
                  }}
                </div>
                <div class="content-body">
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/weight.png"
                    />
                    <div class="value">
                      <div>最大载重</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].maxLoad
                            ? currentDeviceRealData[0].maxLoad
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/weight.png"
                    />
                    <div class="value">
                      <div>当前载重</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].loads
                            ? currentDeviceRealData[0].loads
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/percent.png"
                    />
                    <div class="value">
                      <div>载重百分比</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].loadPercentage
                            ? currentDeviceRealData[0].loadPercentage
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/voltage.png"
                    />
                    <div class="value">
                      <div>当前电池电压</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].voltage
                            ? currentDeviceRealData[0].voltage
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/electric.png"
                    />
                    <div class="value">
                      <div>电量百分比</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].electric
                            ? currentDeviceRealData[0].electric
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 335 / @Width;
}
.row-height-2 {
  height: 335 / @Width;
}
.unload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .r1c1 {
      width: 660 / @Width;
    }
    .r1c2 {
      width: 310 / @Width;
    }
    .r1c3 {
      width: 830 / @Width;
    }
    .r2c1 {
      width: 660 / @Width;
    }
    .r2c2 {
      width: 1160 / @Width;
    }
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}

.r1-c1-content-wrap {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .r1-c1-content-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r1-c1-content-row1-item {
      width: 174 / @Width;
      height: 151 / @Width;
      background-image: url("../assets/device/device-online.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22 / @Width;
      .num {
        color: #ffbe22;
        font-size: 26 / @Width;
        font-weight: 700;
      }
    }
  }
  .r1-c1-content-row2 {
    font-size: 24 / @Width;
    color: #ffbe22;
    margin-top: 20 / @Width;
    margin-bottom: 20 / @Width;
  }
  .r1-c1-content-row3 {
    height: 40 / @Width;
    display: flex;
    .max {
      flex-grow: 1;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 20 / @Width;
      overflow: hidden;
      .val {
        height: 40 / @Width;
        background-color: #ffbe22;
        border-radius: 20 / @Width;
      }
    }
    .percent {
      margin-left: 10 / @Width;
      font-size: 24 / @Width;
      line-height: 40 / @Width;
      color: #ffbe22;
    }
  }
}
.r1c2 {
  .r1c2-list {
    .r1c2-list-item {
      height: 60 / @Width;
      line-height: 60 / @Width;
      text-align: center;
      margin-bottom: 10 / @Width;
      background: linear-gradient(#1b41be, #140a5e);
      border: 1px solid #0079fe;
      color: #00e4ff;
      font-size: 16 / @Width;
    }
    .offline {
      border: 1px solid #eaeaea;
      background: linear-gradient(#c3c2c2, #3e3e3f);
      color: #fff;
    }
    .active {
      box-shadow: 0 0 5 / @Width #fff;
    }
  }
}
.r1c3 {
  .r1c3-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: #fff;
    .menu {
      position: absolute;
      top: -30 / @Width;
      color: #00e4ff;
      font-size: 16 / @Width;
      .menu-item {
        padding: 0 10 / @Width;
        border-right: 1px solid #00e4ff;
      }
      .menu-item:last-child {
        border-right: none;
      }
      .active {
        color: #fff;
      }
    }
    .body {
      width: 100%;
      height: 100%;
    }
  }
}
.r2c1 {
  .r2c1-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: #fff;
    .menu {
      position: absolute;
      top: -30 / @Width;
      color: #00e4ff;
      font-size: 16 / @Width;
      .menu-item {
        padding: 0 10 / @Width;
        border-right: 1px solid #00e4ff;
      }
      .menu-item:last-child {
        border-right: none;
      }
      .active {
        color: #fff;
      }
    }
    .body {
      width: 100%;
      height: 100%;
    }
  }
}
.r2-c2-content-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 30 / @Width;
  box-sizing: border-box;
  .content-top {
    font-size: 18 / @Width;
    position: absolute;
    top: -30 / @Width;
    color: #00e4ff;
  }
  .content-body {
    width: 100%;
    height: 100%;
    // background-color: pink;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-body-item {
      width: 150 / @Width;
      // background-color: #fff;
      .icon {
        width: 150 / @Width;
        height: 150 / @Width;
      }
      .value {
        width: 150 / @Width;
        height: 56 / @Width;
        background-image: url("../assets/device/realData/value-bg.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        margin-top: 10 / @Width;
        text-align: center;
        font-size: 18 / @Width;
        color: #00e4ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Unload",
  components: {},
  data() {
    return {
      onlineData: [],
      deviceListData: [],
      currentDeviceIndex: null,
      menuList: [
        {
          name: "今日",
          value: "4",
        },
        {
          name: "一周",
          value: "3",
        },
        {
          name: "一月",
          value: "2",
        },
      ],
      r1c3MenuListCurrentIndex: 0,
      r2c1MenuListCurrentIndex: 0,
      deviceCurveData: [],
      echarts: {
        r1c3: null,
        r2c1: null
      },
      currentDeviceCurveData: [],
      currentDeviceRealData: []
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    currentDevice: function () {
      return this.deviceListData[this.currentDeviceIndex];
    },
  },
  watch: {
    // 监听项目切换
    currentProject(newVal, oldVal) {
      this.onGetOnlineData(); // 获取设备在线率
      this.onGetDeviceListData(); // 获取设备列表数据
      this.onGetDeviceCurveData(); // 获取设备载重数据
    },
    // 监听当前设备切换
    currentDevice: {
      handler: function (newVal, oldVal) {
        console.log('监听当前设备切换=>', newVal);
        if (!newVal) return
        this.onGetCurrentDeviceCurveData() // 获取当前选中设备报警曲线数据
        this.onGetCurrentDeviceRealData() // 获取当前设备实时数据
        this.onGetCurrentDeviceCurveData(); // 获取当前选中设备报警曲线数据
      },
      deep: true,
    },
    // 监听设备载重数据菜单
    r1c3MenuListCurrentIndex(newVal, oldVal) {
      this.onGetDeviceCurveData(); // 获取设备载重数据
    },
    // 监听载重报警数据菜单
    r2c1MenuListCurrentIndex(newVal, oldVal) {
      this.onGetCurrentDeviceCurveData(); // 获取当前选中设备报警曲线数据
    },
  },
  mounted() {
    this.onGetOnlineData(); // 获取设备在线率
    this.onGetDeviceListData(); // 获取设备列表数据
    this.onGetDeviceCurveData(); // 获取设备载重数据
  },
  methods: {
    // 获取设备在线率
    onGetOnlineData: function () {
      var that = this;
      var data = {
        seType: 2,
        projectId: this.currentProject.id,
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularStatisticsByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.onlineData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备列表数据
    onGetDeviceListData: function () {
      var that = this;
      var data = {
        seType: 2,
        projectId: this.currentProject.id,
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceListData = res.data;
            that.currentDeviceIndex = 0
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备载重数据
    onGetDeviceCurveData: function () {
      var that = this;
      var data = {
        seType: 2,
        projectId: this.currentProject.id,
        timeType: this.menuList[this.r1c3MenuListCurrentIndex].value
      };
      if (!this.currentProject.id) return;
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularCurveByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceCurveData = res.data;
            that.echartsR1C3Init(); // 绘制设备载重数据曲线图
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换当前选中设备
    toggleCurrentDeviceIndex: function (index) {
      this.currentDeviceIndex = index;
    },
    // 切换设备载重数据菜单
    toggleR1c3MenuListCurrentIndex: function (index) {
      this.r1c3MenuListCurrentIndex = index;
    },
    // 切换报警曲线菜单
    toggleR2c1MenuListCurrentIndex: function (index) {
      this.r2c1MenuListCurrentIndex = index;
    },
    // 绘制设备载重数据曲线图
    echartsR1C3Init: function () {
      this.echarts.r1c3 = this.$echarts.init(
        document.getElementById("echarts-r1c3")
      ).dispose();
      this.echarts.r1c3 = this.$echarts.init(
        document.getElementById("echarts-r1c3")
      );
      var colorList = [
        {
          itemColor: "rgba(0,179,244,1)",
          shadowColor1: "rgba(0,179,244,0.8)",
          shadowColor2: "rgba(0,179,244,0.3)",
        },
        {
          itemColor: "rgba(0,202,149,1)",
          shadowColor1: "rgba(0,202,149,0.8)",
          shadowColor2: "rgba(0,202,149,0.3)",
        },
        {
          itemColor: "rgba(255,215,0,1)",
          shadowColor1: "rgba(255,215,0,0.8)",
          shadowColor2: "rgba(255,215,0,0.3)",
        },
        {
          itemColor: "rgba(255,69,0,1)",
          shadowColor1: "rgba(255,69,0,0.8)",
          shadowColor2: "rgba(255,69,0,0.3)",
        },
        {
          itemColor: "rgba(160,32,240,1)",
          shadowColor1: "rgba(160,32,240,0.8)",
          shadowColor2: "rgba(160,32,240,0.3)",
        },
      ];
      var series = [];
      var legend = [];
      this.deviceCurveData.series.forEach((item, index) => {
        var seriesItem = {
          name: item.name,
          type: "line",
          smooth: true, //是否平滑
          showAllSymbol: true,
          symbol: "circle",
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: colorList[index].itemColor,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5,
            },
          },
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: colorList[index].itemColor,
            },
          },
          itemStyle: {
            opacity: 0,
            // borderColor: "#fff",
            // borderWidth: 3,
            // shadowColor: "rgba(0, 0, 0, .3)",
            // shadowBlur: 0,
            // shadowOffsetY: 2,
            // shadowOffsetX: 2,
          },
          tooltip: {
            show: true,
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorList[index].shadowColor2,
                  },
                  {
                    offset: 1,
                    color: "rgba(0,179,244,0)",
                  },
                ],
                false
              ),
              shadowColor: colorList[index].shadowColor1,
              shadowBlur: 20,
            },
          },
          data: this.deviceCurveData.series[index].data,
        };
        series.push(seriesItem);
        legend.push(item.name);
      });
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          data: legend,
          top: 10,
          right: "5%",
          textStyle: {
            color: "#00e4ff",
          },
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "3%",
          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            axisLabel: {
              color: "#00e4ff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.deviceCurveData.time,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false, // Y 轴的竖线
            },
            axisLabel: {
              show: false, // Y 轴的参考单位
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: series,
      };
      this.echarts.r1c3.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c3.resize();
      });
    },
    // 获取当前选中设备报警曲线数据
    onGetCurrentDeviceCurveData: function () {
      this.currentDeviceCurveData = null;
      if (this.currentDeviceIndex == null) {
        return;
      }
      var that = this;
      var data = {
        seType: 2,
        // curveType: 1,
        projectId: this.currentProject.id,
        serialNumber: this.currentDevice.serialNumber,
        timeType: this.menuList[this.r2c1MenuListCurrentIndex].value,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularCurveByPidSidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.currentDeviceCurveData = res.data;
            that.echartsR2C1Init()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取当前设备实时数据
    onGetCurrentDeviceRealData: function () {
      if (this.currentDeviceIndex == null) {
        return;
      }
      var that = this;
      var data = {
        serialNumber: this.currentDevice.serialNumber,
        seType: 2
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularRealDataBySerNumber",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.currentDeviceRealData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 绘制设备报警数据曲线图
    echartsR2C1Init: function () {
      this.echarts.r2c1 = this.$echarts
        .init(document.getElementById("echarts-r2c1"))
        .dispose();
      this.echarts.r2c1 = this.$echarts.init(
        document.getElementById("echarts-r2c1")
      );
      var xData = this.currentDeviceCurveData.time;
      var yData = this.currentDeviceCurveData.series[0].data;
      var option = {
        grid: {
          // x: 35,
          // y: 20,
          // x2: 5,
          // y2: 20,
          top: 10,
          bottom: 20,
          left: 20,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#00e4ff",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "rgba(255,225,255,.1)",
            },
          },
        },
        series: [
          {
            data: yData,
            type: "line",
            smooth: false,
            lineStyle: {
              normal: {
                width: 2,
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.5)",
                    },
                  ],
                  globalCoord: false,
                },
                shadowColor: "rgba(249,165,137, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7,
              },
            },
            itemStyle: {
              normal: {
                color: "#F6D06F",
                borderWidth: 10,
                borderColor: "#F6D06F",
              },
            },
          },
          {
            data: yData,
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(249,165,137, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(249,165,137, 0.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: 0,
              },
            },
          },
        ],
      };
      this.echarts.r2c1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r2c1.resize();
      });
    },
  },
};
</script>